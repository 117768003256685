var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-center",
      staticStyle: { height: "500px" }
    },
    [
      _c(
        "div",
        { staticClass: "mx-auto my-auto" },
        [
          _c("h2", { staticClass: "mx-auto" }, [_vm._v("Call Result")]),
          _c("v-divider", { staticClass: "my-3" }),
          _c(
            "v-radio-group",
            {
              model: {
                value: _vm.disposition,
                callback: function($$v) {
                  _vm.disposition = $$v
                },
                expression: "disposition"
              }
            },
            [
              _vm._l(_vm.items, function(item, index) {
                return [
                  _c("v-radio", {
                    key: index,
                    attrs: { value: item.label, label: item.label }
                  })
                ]
              })
            ],
            2
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-auto mt-4 px-10",
              attrs: {
                large: "",
                color: "secondary",
                disabled: _vm.disposition === null
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("disposition")
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v("mdi-phone-check")
              ]),
              _vm._v(" Submit ")
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }