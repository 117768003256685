<template>
    <div class="d-flex flex-column justify-center" style="height: 500px">
        <div class="mx-auto my-auto">
            <h2 class="mx-auto">Call Result</h2>
            <v-divider class="my-3"></v-divider>
            <v-radio-group v-model="disposition">
                <template v-for="(item, index) in items">
                    <v-radio
                        :key="index"
                        :value="item.label"
                        :label="item.label"
                    ></v-radio>
                </template>
            </v-radio-group>
            <v-btn
                class="mx-auto mt-4 px-10"
                large
                color="secondary"
                :disabled="disposition === null"
                @click.stop="$emit('disposition')"
            >
                <v-icon class="mr-1">mdi-phone-check</v-icon>
                Submit
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    name: "call-disposition",
    components: {},
    props: {},
    data() {
        return {
            disposition: null,
        };
    },
    computed: {
        items() {
            return this.$config("phone.dispositions");
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>